import ApiService from "@/common/api/api.service";

// import helpers from "@/common/utils/helpers";
// import cloneDeep from "lodash.clonedeep";

import Store from "../../../store/Store";
const PlanosService = {
  listar : function(filtro, paginacao = 0, porPagina = 0, ativo, estabelecimento = null){
    let link = `plano?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    let estabecimentoSelecionado = Store.getters['estabelecimentoSelecionado'];
    link = link.concat(`${estabecimentoSelecionado || estabelecimento ? `&estabelecimentoId=${estabelecimento ? estabelecimento : estabecimentoSelecionado}` : ''}`);
    link = link.concat(`${filtro ? `&TermoPesquisa=${filtro}` : ''}`);
    link = link.concat(`${ativo ? `&Ativo=true` : ''}`);
    return ApiService.get(link);
  },
  listarAtivos : function(estabelecimento = null){
    return this.listar(null,0,0,true, estabelecimento);
  },
  salvar : function(form){
    if(form.id){
      return ApiService.put("plano", form);
    }
    return ApiService.post("plano", form);
  },
  ativarInativar : function(form, ativarInativar){
    if(ativarInativar){
      return ApiService.put(`plano/ativar`, form);
    }
    return ApiService.put(`plano/inativar`, form);
  },
  buscar : function(id){
    return ApiService.get(`plano/${id}`);
  },
  buscarFrequencia : function(filtro, paginacao = 1, porPagina = 10){
    let link = `frequencia/dropdown?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    link = link.concat(`${filtro ? `&TermoPesquisa=${filtro}` : ''}`);
    return ApiService.get(link);
  },
  dropdown : function(estabelecimentoId){
    if(!estabelecimentoId)
      return;
    let link = `plano/dropdown`;
    link = link.concat(`?EstabelecimentoId=${estabelecimentoId}`);
    return ApiService.get(link);
  },
};

export default PlanosService;
