<template lang="">
    <div>
        <b-card :no-body="modal">            
            <div class="row">
              <div class="col-12" v-if="!modal">
                  <h4 class="card-title mb-3">
                      {{ $t(`PLANOS.TITULO_FORMULARIO_${!id ? "NOVO" : "EDICAO"}`) }}
                  </h4>
              </div>
              <input-text id="input-nome" class="col-12 col-md-3" :label="$t('PLANOS.NOME_PLANO')" v-model="form.nome" ref="form-nome" required/>
              <input-text id="input-parcelas" class="col-12 col-md-2" :label="$t('PLANOS.NUMERO_COBRANCA')" type="number" v-model="form.parcelas" ref="form-numero-cobranca" required/>
              <input-currency id="input-valor-plano" class="col-12 col-md" :label="$t('PLANOS.VALOR_PLANO')" v-model="form.valorPlano" :required="form.produtos.length == 0" ref="form-valor-plano"/>
              <input-select id="input-frequencia" class="col-12 col-md" :label="$t('PLANOS.FREQUENCIA')" :options="opcoes.frequencias" v-model="form.frequencia" ref="form-frequencia" required/>
                            <input-select id="input-estabelecimento" class="col-12 col-md-3" :label="$t('PLANOS.ESTABELECIMENTO')" :options="opcoes.estabelecimentos" v-model="form.estabelecimentoId" ref="form-estabelecimento" required>
                  <template #label>
                      <div class="d-inline-flex align-items-center">
                          <span class="mr-2">
                              {{$t('PLANOS.ESTABELECIMENTO')}}
                          </span>
                          <feather type="help-circle" size="14" v-b-tooltip.hover :title="$t('PLANOS.TOOLTIP')"></feather>
                      </div>
                  </template>
              </input-select>
              
              <div class="col-12 mb-2">
                  <input-checkbox id="input-aplicar-taxa" switch :text="$t('PLANOS.APLICAR_TAXA')" v-model="form.aplicarTaxaExtra" ref="form-aplicar-taxa" />
              </div>
              <div class="col-12 mb-3" v-if="form.aplicarTaxaExtra">
                  <div class="row">
                      <input-text id="input-descricao-taxa" class="col-12 col-md-6" :label="$t('PLANOS.DESCRICAO_TAXA')" v-model="form.descricaoTaxaExtra" ref="form-descricao-taxa" required/>
                      <input-currency id="input-valor-taxa" class="col-12 col-md" :label="$t('PLANOS.VALOR_TAXA')" v-model="form.valorTaxa" ref="form-valor-taxa" required/>
                      <input-radio id="input-taxa-parcelada" class="col-12 col-md-4" :label="$t('PLANOS.PARCELAS')" :options="parcelas" v-model="form.taxaExtraParcelada" ref="form-parcelas" required/>
                  </div>
              </div>

             <div class="col-12 mb-2">
                  <input-checkbox id="input-incluir-produtos" switch :text="$t('PLANOS.INCLUIR_PRODUTOS')" v-model="form.possuiProduto" ref="form-incluir-produto" />
              </div>
             <div class="row m-1" v-if="form.possuiProduto">
                <div class="col-12 d-flex align-items-center">
                  <!-- <span class="mr-2">{{$t('PLANOS.INCLUIR_PRODUTOS')}}</span> -->
                  <hr class="flex-fill">
                </div>
                <template v-if="permissao">
                  <input-autocomplete id="input-nome-produto" class="col-12 col-md-6" :label="$t('PLANOS.NOME_PRODUTO')" :options="opcoes.produtos" v-model="subform.produto" ref="produto-produto" required/>
                  <input-text id="input-quantidade-produto" class="col-12 col-md-2" :label="$t('PLANOS.QUANTIDADE_PRODUTO')" type="number"  v-model="subform.quantidade" ref="produto-quantidade" :min="0" required/>
                  <input-currency id="input-valor-produto" class="col-12 col-md-4" readonly :label="$t('PLANOS.VALOR_PRODUTO')" v-model="subform.valor" ref="produto-valor" required>
                      <template #append>
                          <b-button id="btn-adicionar-produto" class="ml-2" variant="primary" @click="adicionarProduto">
                              <div class="d-flex align-items-center">
                                  <span>{{$t('PLANOS.ADICIONAR_SACOLA')}}</span>
                              </div>
                          </b-button>
                      </template>
                  </input-currency>
                </template>
              </div>

              <div class="col-12 mb-2">
                  <input-checkbox id="input-aplicar-carencia" switch :text="$t('PLANOS.APLICAR_CARENCIA')" v-model="form.aplicarCarenciaPlano" ref="form-aplicar-carencia" />
              </div>
              <div class="col-12 mb-3" v-if="form.aplicarCarenciaPlano">
                  <div class="row">
                      <input-select id="input-carencia" class="col-12 col-md-3" :label="$t('PLANOS.CARENCIA')" :options="carenciaMeses" v-model="form.carencia" ref="form-carencia" required/>
                      <input-currency id="input-carencia-valor" class="col-12 col-md-3" :label="$t('PLANOS.VALOR_CARENCIA')" v-model="form.carenciaValor" ref="form-carencia-valor" required/>
                  </div>
              </div>

              <valorem-tabela id="tabela-produtos" class="col-12 mt-2" :colunas="colunasProduto" v-model="tabelaProdutos" sem-paginacao rodape>    
                <template #cell(acoes)="data">
                    <div>
                        <b-button class="btn-remover w-100" v-if="permissao" @click="removerProduto(data.item)" variant="flat-valorem-vermelho">{{$t('PLANOS.TABELA_PRODUTO.BOTAO_REMOVER')}}</b-button>
                    </div>
                </template>
                
                <template #foot(nome)>
                    <span>{{$t('PLANOS.VALOR_TOTAL')}}</span>
                </template>
                <template #foot(valor)>
                    <span>{{valorTotal}}</span>
                </template>
                <template #foot()>
                    <span></span>
                </template>
              </valorem-tabela>

              <div v-if="!modal" class="col-12 mt-4 d-flex justify-content-end">
                <b-button id="btn-cancelar" @click="cancelar" variant="outline-success">
                    Cancelar
                </b-button>        
                <b-button id="btn-salvar" @click="salvar" class="ml-2" v-if="permissao" variant="success">
                    Salvar
                </b-button>        
              </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import { InputText, InputCurrency, InputSelect, InputCheckbox, InputAutocomplete, InputRadio } from '@/components/inputs';
import PlanosService from '@/common/services/planos/planos.service';
import ProdutosService from  "@/common/services/produtos/produtos.service";
import MinhaContaService from '@/common/services/usuario/minha-conta.service';
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers"
import cloneDeep from 'lodash.clonedeep';
export default {
    props : {
        id: {},
        modal : {
            type : Boolean,
            default : false
        }
    },
    components : {
        InputText,
        InputCurrency,
        InputSelect,
        InputCheckbox,
        InputAutocomplete,
        InputRadio
    },
    data : function(){
        return {
            form : {
                nome : "",
                valorPlano : 0,
                parcelas : 0,
                frequencia  : 0,
                estabelecimentoId : "",
                aplicarCarenciaPlano : false,
                carencia : 0,
                aplicarTaxaExtra : false,
                descricaoTaxaExtra : "",
                valorTaxa : 0,
                taxaExtraParcelada : 0,
                possuiProduto : false,
                produtos : [],
                carenciaValor: null,
            },
            subform : {
                produto : "",
                quantidade : 1,
                valor : "",
            },
            opcoes : {
                situacoes : helpers.Situacao,
                frequencias : helpers.Frequencia,
                estabelecimentos : [],
                produtos : []
            },            
            colunasProduto : [
                {key : "codigo", label : '#'},
                {key : "nome", label : this.$t('PLANOS.NOME_PRODUTO'), thClass : "w-50"},
                {key : "valor", label : this.$t('PLANOS.VALOR_PRODUTO'), formatter : (v) => { if(v >= 0) return helpers.formatarValor(v)}},
                {key : "quantidade", label : this.$t('PLANOS.QUANTIDADE_PRODUTO')},
                {key : "acoes", label : ''},
            ],
            estabelecimentoAntigo : null
        }
    },
    methods : {
        salvar : function(){
            return new Promise((resolve) => {
                this.confirmar(this.$t('PLANOS.TITULO_SALVAR'), this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")).then(confirmado => {
                    if(!confirmado)
                    return;

                    /*if(!this.form.produtos.length){
                        this.toastErro(this.$t('PLANOS.PRODUTOS_OBRIGATORIO'))
                        return false;
                    }*/
                    
                    if(!this.validarFormulario('form-'))
                        return false;

                    this.$store.dispatch(START_LOADING);
                    let form = cloneDeep(this.form);
                    form.valorPlano = helpers.removerMascaraValor(this.form.valorPlano);
                    form.carenciaValor = helpers.removerMascaraValor(this.form.carenciaValor);
                    if (form.valorTaxa) form.valorTaxa = helpers.removerMascaraValor(this.form.valorTaxa);
                    if (form.aplicarCarenciaPlano) form.carenciaValor = helpers.removerMascaraValor(this.form.carenciaValor);
                    
                    if(!form.valorPlano)
                        form.valorPlano = 0;

                    if(!form.aplicarCarenciaPlano) {
                        form.carencia = 0;
                        form.carenciaValor = 0
                    }

                    if(!form.aplicarTaxaExtra)
                        form.valorTaxa = 0;
                        
                    PlanosService.salvar(form).then(() => {
                        this.alertaSucesso(this.$t('PLANOS.MENSAGEM_SUCESSO')).then(() => {
                        if(!this.modal) this.$router.push({name : "planos"});
                        resolve();
                        })
                    }).finally(() => {
                        this.$store.dispatch(STOP_LOADING);
                    })
                })
            });
        },
        cancelar : function(){
            this.$router.push({name : "planos"});
        },
        buscar : function(){
            this.$store.dispatch(START_LOADING);
            PlanosService.buscar(this.id).then(res => {
                this.form = res.data.data;
                this.form.valorPlano = helpers.formatarValor(this.form.valorPlano);
                this.form.valorTaxa = helpers.formatarValor(this.form.valorTaxa);
                this.form.carenciaValor = helpers.formatarValor(this.form.carenciaValor);
            }).finally(() => {
                this.$store.dispatch(STOP_LOADING);
            })
        },
        buscarProdutos : function(){
            this.opcoes.produtos = []
            this.$store.dispatch(START_LOADING);
            ProdutosService.dropdown(this.form.estabelecimentoId).then(res => {
                this.opcoes.produtos = res.data.data.produtos.map(el => {
                    return {
                        value : {value : el.id, text : el.nome, valor : el.valor, codigo : el.codigo},
                        text : `${el.codigo} - ${el.nome}`,
                    }
                });
            }).finally(() => {
                this.$store.dispatch(STOP_LOADING);
            })
        },
        buscarEstabelecimentos : function(){
            this.$store.dispatch(START_LOADING);
            MinhaContaService.buscarEstabelecimento().then(res => {
                this.opcoes.estabelecimentos = res.data.data.estabelecimentos.map(el => {
                    return {
                        value : el.id,
                        text : el.nome
                    }
                });
            }).finally(() => {
                this.$store.dispatch(STOP_LOADING);
            })
        },
        adicionarProduto : function(){
            if(!this.validarFormulario("produto-"))
                return;

            let subform = cloneDeep(this.subform);
            let produto = {
                codigo : subform.produto.codigo,
                quantidade : `${subform.quantidade}`.replace(",", "."),
                valor : helpers.removerValor(subform.valor),
                produtoId : subform.produto.value,
                nome : subform.produto.text 
            }

            this.form.produtos.push(produto);

            this.limparFormulario("subform-");
        },
        removerProduto : function(item){
            if(item.produtoId == "-"){
                this.form.valorTaxa = "";
                this.form.descricaoTaxaExtra = "";
                this.form.aplicarTaxaExtra = false;
            }else{
                let itemExcluir = this.form.produtos.find(el => {
                    return el.produtoId == item.produtoId &&
                        el.quantidade == item.quantidade &&
                        el.valor == item.valor
                });
                this.form.produtos.splice(this.form.produtos.indexOf(itemExcluir), 1);
            }    
        },
    },
    mounted(){
        if(!this.modal)
            this.$store.dispatch(DEFINIR_BREADCRUMB, [
                { titulo: this.$t("PLANOS.TITULO_LISTA"), to : "/planos" },
                { titulo: this.$t(`PLANOS.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`) },
            ]);
        if(this.id){
            this.buscar();
        }else{
            this.form.estabelecimentoId = this.$store.getters['estabelecimentoSelecionado'];
        }
        this.buscarEstabelecimentos();
        this.buscarProdutos();
    },
    watch : {
        'subform.produto' : function(produto){
            this.subform.valor = helpers.formatarValor(produto.valor);
        },
        'form.estabelecimentoId' : function(novo, antigo){
            this.buscarProdutos();
            if(this.form.produtos.length > 0 && antigo != "" && this.estabelecimentoAntigo != novo){
                this.confirmar("Aviso", "Alterar o estabelecimento resultará na remoção dos produtos adicionados, deseja executar essa ação?").then(confirmado => {
                    if(!confirmado){
                        this.estabelecimentoAntigo = antigo
                        this.form.estabelecimentoId = antigo;
                        return;
                    }

                    this.form.produtos = []
                });
            }
        }
    },
    computed : {
        permissao: function () {
            return this.form.id ? helpers.validarAcesso("Plano").criar : helpers.validarAcesso("Plano").editar;
        },
        tabelaProdutos : function(){
            let tabela = [];
            tabela.push(...this.form.produtos);
            
            let valorPlano = helpers.removerValor(`${this.form.valorPlano}`);
            if(valorPlano > 0){
                tabela.push({
                    codigo : '-',
                    produtoId : '.',
                    nome : `Valor do plano`,
                    quantidade : this.form.parcelas,
                    valor : valorPlano || 0
                })
            }
            
            if(this.form.aplicarTaxaExtra){
                let valor = helpers.removerValor(`${this.form.valorTaxa}`) / this.form.parcelas;
                tabela.push(
                    {
                        codigo : '-',
                        produtoId : '-',
                        nome : `(Taxa extra) - ${this.form.descricaoTaxaExtra || 'Sem descrição'} - ${this.form.taxaExtraParcelada ? `Parcelado em ${this.form.parcelas} vezes de ${helpers.formatarValor(valor)}` : 'À vista'}`,
                        quantidade : 1,
                        valor : helpers.removerValor(`${this.form.valorTaxa}`) || 0
                    }
                );
            }
            
            if(this.form.aplicarCarenciaPlano){
                tabela.push(
                    {
                        codigo : '-',
                        produtoId : '-',
                        nome : `(Taxa carência) - 'À vista'}`,
                        quantidade : 1,
                        valor : helpers.removerValor(`${this.form.carenciaValor}`) || 0
                    }
                );
            }
            
            return tabela;
        },
        
        valorTotal : function(){
            let total = this.tabelaProdutos.reduce((total, produto) => {
                return total + (produto.valor * produto.quantidade);
            }, 0);
            return helpers.formatarValor(total)
        },

        carenciaMeses : function(){
            let carencia = [];
            if(!this.form.parcelas)
                return [];
            for (let i = 1; i <= this.form.parcelas; i++) {
                carencia.push({
                    value : i,
                    text : `${i} ${i == 1 ? 'mês' : 'meses'}`
                })                
            }
            return carencia;
        },

        parcelas : function(){
            let valor = helpers.removerValor(`${this.form.valorTaxa}`) / this.form.parcelas;
            let parcelas = [
                {
                    value : false,
                    text : 'À Vista'
                },
            ];

            if(this.form.parcelas > 1){
                parcelas.push({
                    value : true,
                    text : `Parcelado em ${this.form.parcelas} vezes de ${helpers.formatarValor(valor)}`
                })
            }
            return parcelas;
        },
    },

}
</script>
<style lang="">
    
</style>